import React from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

function Header() {
  return (
    <header color="primary" style={{margin: '10px'}}>
        <Typography variant="h1" color="primary">
            Rubic Shopping
        </Typography>
      <Divider/>
    </header>
  );
}

export default Header;