import { createTheme } from '@mui/material/styles';



const useTheme = createTheme({
  palette: {
    primary: {
      main: '#00a19b',
    },
    secondary: {
      main: '#e74015',
    },
    highlight: {
      main: '#e74015',
    },
    bright: {
      main: 'white',
    },
    disabled: {
      main: 'grey',
    }
  },
  typography: {
    h1: {
        fontSize: '2em'
    }
  }
});

export default useTheme;
