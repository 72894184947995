import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import './App.css';
import Main from './components/main.component';
import Header from './components/header.component';
import theme from './theme';

function App() {

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Header />
        <Main />
      </div>
    </ThemeProvider>
  );
}

export default App;
