import {React } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/system';

function ShoppingList({products, setProducts}) { 

    const theme = useTheme();

    console.log(theme);

    const handleToggle = (product) => () => {
        const currentIndex = products.indexOf(product);
        const newProducts = [...products];    
        newProducts[currentIndex].purchased = !newProducts[currentIndex].purchased;    
        setProducts(newProducts);
    };

    const doneShopping = () => {        
        const newProducts = [...products];    
        newProducts.forEach((el)=>{el.onList = false; el.purchased = false; el.count = 1;}) 
        setProducts(newProducts);
    }

    const handlePlusOne = (product) => {
        const newProducts = [...products];    
        const index = newProducts.map(e => e.name).indexOf(product.name);
        newProducts[index].count++;
        setProducts(newProducts);
      };

    const handleMinusOne = (product) => {
        const newProducts = [...products];    
        const index = newProducts.map(e => e.name).indexOf(product.name);
        newProducts[index].count--;

        if(newProducts[index].count == 0){
            newProducts[index].onList = false;
            newProducts[index].purchased = false;
        }
        setProducts(newProducts);
    };

    return (
        <div>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon color="bright" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{backgroundColor: theme.palette.primary.main}}
                >
                    <Typography sx={{color: theme.palette.bright.main}}>Handleliste</Typography>
                    <Badge badgeContent={products.filter(product => product.onList).length} color="secondary"></Badge>
                </AccordionSummary>
                <AccordionDetails>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {products.filter(product => product.onList).map((product) => {
                        return (<ListItem
                            key={product.name}
                            disablePadding
                            >
                            <ListItemButton role={undefined} onClick={handleToggle(product)} dense>
                                <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={product.purchased}
                                    tabIndex={-1}
                                    disableRipple
                                />
                                </ListItemIcon>
                                <ListItemText id={product.name} primary={product.name}
                                    sx={{textDecoration: product.purchased && 'line-through', color: product.purchased && theme.palette.disabled.main}}
                                 />
                                {product.count > 1 && (
                                    <Chip label={product.count}/>
                                )}
                            </ListItemButton>
                            {product.purchased === false && (
                                <ListItemSecondaryAction>                                    
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleMinusOne(product)}>
                                        <RemoveCircleOutlineIcon />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" onClick={() => handlePlusOne(product)}>
                                        <AddCircleOutline />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            )}
                        </ListItem>);
                        })}
                    </List>
                    {products.filter(p => p.onList).length > 0 && products.filter(p => p.onList).length === products.filter(p => p.onList && p.purchased).length && (
                        <div>
                            <Typography color="primary" variant="h5" sx={{display: 'block'}}>
                                Gratulerer!
                            </Typography>
                            <Typography color="primary" variant="p" sx={{display: 'block'}}>
                                {'Du kan gå til kassen:)'}
                            </Typography>
                            <br/>
                            <Button variant="contained" onClick={doneShopping}>Avslutt handleturen</Button>
                        </div>
                    )}
                    {products.filter(product => product.onList).length === 0 && (
                        <Typography variant='p'>
                            Handlelisten din er tom
                        </Typography>
                    )}
                </AccordionDetails>
            </Accordion>        
            
      </div>
    );
}

export default ShoppingList;