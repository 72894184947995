import React, { useState, useEffect, useRef } from 'react';
import ShoppingList from './shoppingList.component';
import ProductList from './productList.component';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set, onValue } from 'firebase/database';
import { Box } from '@mui/system';


const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

function Main() {
    const [products, setProducts] = useState([]);
    const isInitialLoad = useRef(true);

    useEffect(() => {
        const itemsRef = ref(database, 'products');
        const unsubscribe = onValue(itemsRef, (snapshot) => {
            const dataFromDatabase = snapshot.val();
            const dataArray = dataFromDatabase ? Object.values(dataFromDatabase) : [];
            setProducts(dataArray);
            isInitialLoad.current = false;
        });

        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (isInitialLoad.current) {
            return; // Skip the rest of the useEffect on initial load
        }

        products.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

        const itemsRef = ref(database, 'products');
        set(itemsRef,{...products});
    }, [products]);

    return (
        <Box style={{
            backgroundImage: 'url("rubic_logo.png")',
            backgroundSize: '90%',
            backgroundPosition: 'bottom',
            width: '100vw',
            height: '100vh',
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: '290%',
            backgroundPositionY: '100%'
          }}>
            <ShoppingList products={products} setProducts={setProducts} />
            <ProductList products={products} setProducts={setProducts} />
        </Box>
    );
}

export default Main;