import {React, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Badge from '@mui/material/Badge';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Input from '@mui/material/Input';
import Snackbar from '@mui/material/Snackbar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/system';

function ProductList({products, setProducts}) { 

    const theme = useTheme();

    const handleToggle = (product) => () => {
        const currentIndex = products.indexOf(product);
        const newProducts = [...products];    
        newProducts[currentIndex].onList = true;
        newProducts[currentIndex].purchased = false;    
        setProducts(newProducts);
    };

    const [isInputVisible, setIsInputVisible] = useState(false);
    const [newItemText, setNewItemText] = useState('');
    const handleAddItem = () => {
        const newItemName = newItemText.trim();
        if(newItemName.length > 0){
            if(products.filter(p => p.name === newItemName).length === 0){
                const newItem = {name: newItemName, count: 1, purchased: false, onList: false};
                setProducts((prevItems) => [...prevItems, newItem]);
            }else{
                setSnackOpen(true);
            }
        }
        setIsInputVisible(false);
        setNewItemText('');
    };
    const handleDeleteItem = (product) => {
        const newProducts = [...products];    
        const index = newProducts.map(e => e.name).indexOf(product.name);
        newProducts.splice(index, 1);
        setProducts(newProducts);
      };
    
    const handleFabClick = () => {
        setIsInputVisible(true);
    };
    const [snackOpen, setSnackOpen] = useState(false);

    return (
        <div>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon color="bright" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{backgroundColor: theme.palette.primary.main}}
                >
                    <Typography sx={{color: theme.palette.bright.main}}>Produkter</Typography>
                    <Badge badgeContent={products.filter(product => product.onList === false).length} color="secondary">
                    </Badge>
                </AccordionSummary>
                <AccordionDetails>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {products.filter(product => product.onList === false).map((product) => {
                        return (<ListItem
                            key={product.name}
                            disablePadding
                            >
                            <ListItemButton role={undefined} onClick={handleToggle(product)} dense>
                                <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={product.onList}
                                    tabIndex={-1}
                                    disableRipple
                                />
                                </ListItemIcon>
                                <ListItemText id={product.name} primary={product.name} />
                                
                            </ListItemButton>
                            <ListItemSecondaryAction>
                                    <IconButton color="highlight" edge="end" aria-label="delete" onClick={() => handleDeleteItem(product)}>
                                        <DeleteIcon />
                                    </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>);
                        })}
                    </List>
                    {products.length === 0 && (
                        <Typography variant='p'>
                            Legg til produkter ved å trykke på pluss-ikonet til høyre
                        </Typography>
                    )}
                    {products.length > 0 && products.filter(product => product.onList === false).length === 0 && (
                        <Typography variant='p'>
                            Ingen flere produkter tilgjengelig
                        </Typography>
                    )}
                    <div style={{ position: 'fixed', right: '16px', bottom: '5px' }}>
                    {isInputVisible ? (
                        <div>
                        <Input
                            value={newItemText}
                            onChange={(e) => setNewItemText(e.target.value)}
                            placeholder="Produktnavn"
                        />
                        <Fab color="primary" onClick={handleAddItem}>
                            <AddIcon />
                        </Fab>
                        </div>
                    ) : (
                        <Fab color="primary" onClick={handleFabClick}>
                        <AddIcon />
                        </Fab>
                    )}
                    </div>
                    <br/><br/>
                    <Snackbar open={snackOpen} severity="warning" autoHideDuration={2000} message="Produktet finnes allerede">
                    </Snackbar>
                </AccordionDetails>
            </Accordion>        
            
      </div>
    );
}

export default ProductList;